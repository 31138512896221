<template>
  <div id="vedioWorkDetail">
    <div class="imageWorkDetail-container" v-if="data.workInfo != null">
      <div class="title">
        <span>{{ data.workInfo.authorName }}</span><span>{{ data.workInfo.name }}</span>
      </div>
      <div class="work-detail">
        <div class="work-item-detail-box">
          <div class="work-item-cate">
            <span style="vertical-align: middle">{{ typeList[data.workInfo.category - 1].name }}</span>
          </div>
          <div class="work-item-cate">
            <span style="vertical-align: middle">{{ groupList[data.workInfo.competitionUnit - 1].name }}</span>
          </div>
          <div class="work-item-createtime">
            <span style="vertical-align: middle" v-if="data.workInfo.createTime">{{ formatDate(data.workInfo.createTime) }}</span>
          </div>
          <div class="detail-right">
            <div class="report" @click="() => dialogVisible = true">
              <img src="@/assets/img/report-icon.png" alt="">
              <span>举报</span>
            </div>
            <div class="view">
              <img src="@/assets/img/view-icon.png" alt="">
              <span>{{ viewCount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="work-desc">
        <p>
          {{ data.workInfo.description }}
        </p>
      </div>
      <div class="Image-box">
        <div class="videoBox">
          <video
            :src="data.workInfo.contents[0]"
            style="height: 100%; width: 100%"
            controls="controls"
            controlsList="nodownload"
            @contextmenu.prevent.stop="rightClick($event)"
          ></video>
        </div>
      </div>
      <div class="share-wrap">
        <div class="share-text">点击分享:</div>
        <el-popover placement="top" :width="100" trigger="click" @show="showCode" style="min-width: auto">
          <template #reference>
            <div class="share-icon" @click="shareEvent('wechat')" :style="{
                backgroundImage: `url(${require(`../assets/img/share/wechat.png`)})`,
              }"></div>
          </template>
          <div id="qrcode" ref="qrcode" style="width: 100px"></div>
        </el-popover>
        <template v-for="(item, index) in shareIcon">
          <div v-if="item !== 'wechat'" :key="index" class="share-icon" :class="{ 'copy-url': item === 'url' }"
            @click="shareEvent(item)" :style="{
                backgroundImage: `url(${require(`../assets/img/share/${item}.png`)})`,
              }"></div>
        </template>
      </div>
    </div>
    <div class="report-form">
      <el-dialog v-model="dialogVisible">
        <p class="title">举报</p>
        <el-form label-position="top">
          <el-form-item label="被举报人">
            <el-input v-model="reportData.authorName" class="input-item" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="举报作品">
            <el-input v-model="reportData.name" class="input-item" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="*举报原因">
            <div class="reason-wrap">
              <div v-for="(item, i) in reasons.reasonList"
                :class="[reportData.reason.indexOf(item) !== -1 ? 'reasonactive' : '', 'reason-item']" :key="item"
                @click="toogleReason(item)">
                {{ item }}
              </div>
            </div>
          </el-form-item>
          <el-form-item label="*举报描述" prop="description">
            <el-input type="textarea" v-model="reportData.description" maxlength="200" show-word-limit class="input-item"
              resize="none" placeholder="200个字以内">
            </el-input>
          </el-form-item>
          <el-form-item label="*上传证据">
            <p>支持jpg\png\gif格式，大小不要超过20M</p>
            <el-upload ref="coverFile" class="myfile2" list-type="picture-card" :limit="1"
              :file-list="reportData.evidenceList" accept="image/gif,image/jpeg,image/x-png"
              :on-change="(file) => uploadAli(file)" :auto-upload="false">
              <img class="upload-icon" src="@/assets/img/upload-icon.png" alt="">
              <p class="upload-type">点击上传</p>
              <template #file="{ file }">
                <div>
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <span class="el-upload-list__item-actions">
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </template>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="submit-report-btn" @click="reportSubmit">确认提交</div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ref, reactive, nextTick } from "vue";
import QRCode from "qrcodejs2";
import { getOssSignature } from "../utils/apis/fileApi";
import { upload } from "../utils/uploadSign";
import { submitReport } from "../utils/apis/userApi"
import { ElMessage } from "element-plus";
import { microBlog, qqSpace, QQ } from "../utils/share";
import { useRoute, useRouter } from "vue-router";
import { getById, markView, increaseView, getByIdSub, markSubView, increaseSubView } from "../utils/apis/worksApi.js";
import baseInfo from "../utils/config";
export default {
  name: "vedioWorkDetail",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const shareIcon = reactive(["wechat", "QQ", "weibo", "qqSpace"]);
    const currentUrl = ref(window.location.href);
    const markRes = ref("");
    const qrcodes = ref(null);
    const viewCount = ref(route.query.views)
    const dialogVisible = ref(false)
    const data = reactive({
      workInfo: null,
    });
    const groupList = [
      {
        name: "专业组",
        id: 1
      },
      {
        name: "高校组",
        id: 2
      }
    ]
    const typeList = [
      {
        name: "设计类",
        id: 1
      },
      {
        name: "Ani动画类",
        id: 2
      },
      {
        name: "CG类",
        id: 3
      }
    ]
    const reportData = reactive({
      authorId: null,
      authorName: '',
      name: '',
      reason: [],
      description: '',
      evidenceList: []
    })
    const reasons = reactive({
      reasonList: [
        '抄袭', '政治敏感', '色情', '辱骂他人', '违反规则'
      ]
    })
    const mysign = reactive({
      data: null,
    });
    getSignature()
    getDetail();
    mark();

    function rightClick(event) {
      console.log(event);
    }
    function qrcode() {
      console.log(baseInfo);
      if (qrcodes.value) {
        qrcodes.value.clear();
        qrcode();
      } else {
        let url =
          route.query.type === 1
            ? `
        ${baseInfo.MOBILE_URL}themeWorksVideoWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.type}&views=${route.query.views}`
            : `${baseInfo.MOBILE_URL}freeThemeWorksVideoWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.type}&views=${route.query.views}`;
        qrcodes.value = new QRCode("qrcode", {
          width: 100,
          height: 100, // 高度
          text: url, // 二维码内容
          // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
          // background: '#f0f'
          // foreground: '#ff0'
        });
      }
    }
    async function showCode() {
      await nextTick();
      qrcode();
    }
    /**
     * 分享
     */
    function shareEvent(type) {
      console.log(type);
      if (type === "QQ") {
        let mobileurl =
          route.query.type === 1
            ? `
        ${baseInfo.MOBILE_URL}themeWorksVideoWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.type}&views=${route.query.views}`
            : `${baseInfo.MOBILE_URL}freeThemeWorksVideoWorkDetail?id=${route.query.id}&type=${route.query.type}&cate=${route.query.type}&views=${route.query.views}`;
        let url = QQ({
          author: data.workInfo.authorName,
          worksname: data.workInfo.name,
          description: data.workInfo.description,
          url: mobileurl,
          pic: data.workInfo.contents[0],
        });

        console.log(url);
        window.open(encodeURI(url));
      }
      if (type === "qqSpace") {
        let url = qqSpace({
          author: data.workInfo.authorName,
          worksname: data.workInfo.name,
          description: data.workInfo.description,
          url: location.href,
          pic: data.workInfo.contents[0],
        });

        console.log(url);
        window.open(encodeURI(url));
      }
      if (type === "weibo") {
        // console.log
        let url = microBlog({
          author: data.workInfo.authorName,
          worksname: data.workInfo.name,
          url: location.href,
          pic: data.workInfo.contents[0],
        });
        window.open(encodeURI(url));
      }
    }
    function getDetail() {
      if (route.query.subId) {
        getByIdSub({ id: route.query.id, subId: route.query.subId }).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            data.workInfo = res.data.data;
            reportData.authorName = data.workInfo.authorName
            reportData.name = data.workInfo.name
            reportData.authorId = data.workInfo.authorId
          }
        });
      } else {
        getById({ id: route.query.id }, !!route.query.isAward).then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            data.workInfo = res.data.data;
            reportData.authorName = data.workInfo.authorName
            reportData.name = data.workInfo.name
            reportData.authorId = data.workInfo.authorId
          }
        });
      }
    }
    function goBack() {
      if (route.query.type == 2) {
        router.push({ path: "/freeThemeWorks" });
      } else {
        router.push({ path: "/ThemeWorks" });
      }
    }

    function mark() {
      if (!route.query.subId) {
        markView({ id: route.query.id }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            markRes.value = res.data.data;
            setTimeout(() => {
              saveView();
            }, 6000);
          }
        });
      } else {
        markSubView({ id: route.query.id }).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            markRes.value = res.data.data;
            setTimeout(() => {
              saveView();
            }, 6000);
          }
        });
      }
    }

    function saveView() {
      if (!route.query.subId) {
        increaseView({ id: route.query.id, code: markRes.value }).then((res) => {
          console.log(res);
        });
      } else {
        increaseSubView({ id: route.query.id, code: markRes.value }).then((res) => {
          console.log(res);
        });
      }

    }
    function toogleReason(reason) {
      let index = reportData.reason.indexOf(reason)
      if (index === -1) {
        reportData.reason.push(reason)
      } else {
        reportData.reason.splice(index, 1)
      }
    }
    function getSignature() {
      //获取签名
      getOssSignature().then((res) => {
        const sign = res.data.data;
        mysign.data = sign;
      });
    }


    function uploadAli(file) {
      console.log(file)
      const params = new FormData();
      params.append(
        "key",
        mysign.data.dir +
        `${dateFormat("yyyy-MM-dd")}/` +
        `${randomString()}${getSuffix(file.name)}`
      );
      params.append("OSSAccessKeyId", mysign.data.accessKeyId);
      params.append("policy", mysign.data.policy);
      params.append("signature", mysign.data.signature);
      params.append("callback", mysign.data.callback);
      params.append("success_action_status", "200"); // 上传成功返回的状态码，不设置则是204
      params.append("file", file.raw); // 一定在最后
      if (mysign.data.expiration < Date.now() + 60 * 1000) {
        getSignature();
      }
      upload(params, mysign.data.host).then(res => {
        if (res.data.code === 200) {
          reportData.evidenceList.push({
            url: res.data.data.url
          })
        }
      })
    }

    function dateFormat(fmt) {
      let getDate = new Date();
      let o = {
        "M+": getDate.getMonth() + 1,
        "d+": getDate.getDate(),
        "h+": getDate.getHours(),
        "m+": getDate.getMinutes(),
        "s+": getDate.getSeconds(),
        "q+": Math.floor((getDate.getMonth() + 3) / 3),
        S: getDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      if (fmt.includes("NaN")) {
        return "请刷新页面后重试";
      }
      return fmt;
    }

    function randomString(len) {
      len = len || 16;
      let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678";
      let maxPos = chars.length;
      let str = "";
      for (let i = 0; i < len; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return str; sign
    }

    function getSuffix(filename) {
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos !== -1) {
        suffix = filename.substring(pos);
      }
      return suffix;
    }
    //移除文件
    function handleRemove(file) {
      let index = reportData.evidenceList.findIndex((item) => item.url === file.url);
      reportData.evidenceList.splice(index, 1);
      console.log(JSON.stringify(reportData.evidenceList))
    }

    //提交举报
    function reportSubmit() {
      if (reportData.description && reportData.evidenceList.length && reportData.reason.length) {
        submitReport(JSON.stringify({
          authorId: reportData.authorId,
          authorName: reportData.authorName,
          name: reportData.name,
          reason: reportData.reason,
          description: reportData.description,
          evidenceList: reportData.evidenceList.map((item) => item.url)
        })).then(res => {
          ElMessage({
            message: "提交成功！",
            type: "success",
            customClass: "myElMessage",
          });
          reportData.authorName = ''
          reportData.authorId = null
          reportData.name = ''
          reportData.reason = []
          reportData.description = ''
          reportData.evidenceList = []
          dialogVisible.value = false
          console.log(res)
        })
      } else {
        ElMessage({
          message: "请补全举报信息",
          type: "error",
          customClass: "myElMessage",
        });

      }
      console.log(JSON.stringify(reportData))
    }
    function formatDate(date) {
      let d = new Date(date)
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      let day = d.getDate()
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }
    return {
      shareIcon,
      shareEvent,
      rightClick,
      currentUrl,
      route,
      goBack,
      data,
      qrcode,
      showCode,
      viewCount,
      reasons,
      dialogVisible,
      reportData,
      toogleReason,
      uploadAli,
      handleRemove,
      reportSubmit,
      formatDate,
      typeList,
      groupList
    };
  },
};
</script>

<style lang="less" scoped>
#vedioWorkDetail {
  .imageWorkDetail-container {
    width: 1030px;
    margin: 0 auto;
    padding-top: 30px;
    overflow: hidden;
  }

  .search-box {
    margin: 0 0 25px 0;
    height: 30px;
    display: flex;
    line-height: 30px;
    border-radius: 15px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    width: 240px;
    padding-left: 15px;

    .icon-box {
      font-size: 22px;
      color: #dcdfe6;
    }

    .input-box {
      font-size: 0px;
      height: 28px;
      padding-left: 10px;
    }

    .input-box input {
      border: none;
      box-sizing: border-box;
      height: 100%;
      outline: none;
      color: #909399;
      width: 170px;
      background-color: #ffff;
    }
  }

  .bread-title {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
  }

  .list-name {
    float: left;
    margin-right: 25px;

    span:first-child {
      margin-right: 8px;
      margin-left: 5px;
    }

    span {
      font-weight: bold;
      color: #000;
    }
  }

  .title {
    color: #303133;
    font-weight: bold;
    font-size: 18px;
    margin-top: 45px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 20px;

    span:first-child {
      margin-right: 8px;
    }
  }

  .work-detail {
    margin-bottom: 32px;

    .work-item-detail-box {
      margin-top: 5px;
      height: 14px;
      position: relative;

      .work-item-cate,
      .work-item-createtime,
      .work-item-views {
        display: inline-block;
        color: #909399;
        font-size: 14px;
        margin-right: 11px;
      }

      .work-item-createtime {
        margin-right: 12px;
      }
    }

    .detail-right {
      font-size: 12px;
      float: right;
      width: 102px;
      display: flex;
      justify-content: space-around;
      line-height: 30px;

      .report {
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;

        &>img {
          width: 12px;
          height: 11px;
          margin-right: 5px;
        }
      }

      .view {
        display: flex;
        justify-content: space-around;
        align-items: center;

        &>img {
          width: 17px;
          height: 11px;
          margin-right: 6px;
        }
      }
    }
  }

  .work-desc {
    line-height: 30px;
    color: #303133;
    margin-bottom: 45px;
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
    color: #999999;

    &>p {
      display: inline-block;
    }
  }

  .Image-box {
    padding-bottom: 40px;
  }

  .videoBox {
    height: 820px;
    margin-bottom: 40px;
    background-color: #303133;
  }

  .share-wrap {
    float: right;
    display: flex;
    width: 220px;
    margin-bottom: 60px;

    justify-content: space-between;
    overflow: hidden;

    .share-text {
      font-size: 16px;
      color: #909399;
    }

    .share-icon {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 28px;
      height: 22px;
      cursor: pointer;
    }
  }

  .report-form {
    ::v-deep .el-dialog {
      box-sizing: border-box;
      width: 614px !important;
      height: 882px;
      border-radius: 4px;
      background-color: #F7F7F7;
      padding-left: 27px;

      .title {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
      }

      .el-form-item__label {
        padding: 0 19px 0 0;
      }

      .avator-form-item {
        .el-form-item__label {
          line-height: 80px;
        }
      }

      .input-item {
        width: 300px;
        position: relative;
      }

      .el-textarea {
        width: 520px;

        .el-textarea__inner {
          width: 520px;
          height: 90px;
        }
      }

      .reason-wrap {
        width: 410px;


        .reason-item {
          width: 70px;
          float: left;
          height: 34px;
          line-height: 34px;
          color: rgba(0, 0, 0, .3);
          border: 1px solid #cccccc;
          text-align: center;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .reasonactive {
          border: 1px solid #325888;
        }
      }
    }

    .submit-report-btn {
      width: 152px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      bottom: 44px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #325888;
      border-radius: 4px;
      cursor: pointer;
      color: #fff;
      text-align: center;
    }

    .myfile2 {
      .upload-icon {
        width: 25px;
        height: 24px;
        position: absolute;
        top: 58px;
        left: 50%;
        transform: translateX(-50%);
      }

      .upload-type {
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: center;
        color: #666666;
        line-height: 20px;
        position: absolute;
        top: 94px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .upload-content {
      ::v-deep img {
        object-fit: cover;
      }
    }

    .upload-tip {
      margin: 8px 0;
      line-height: 14px;
      font-size: 14px;
      color: #c0c4cc;
      height: 14px;
    }
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 270px;
    height: 170px;
    line-height: 170px;
  }

  ::v-deep .el-upload--picture-card {
    width: 270px;
    height: 170px;
    line-height: 170px;
    position: relative;
    background-color: #fff;
  }


  ::v-deep .el-upload {
    position: relative;
    width: 260px;
    height: 170px;
  }
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}
</style>
<style>
.el-popover.el-popper {
  min-width: auto;
}
</style>
